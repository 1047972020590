
<template>
  <v-expansion-panel 
    @click="handleExpand"
  >
    <v-expansion-panel-header>{{ $t('booking.categories', locale) }}</v-expansion-panel-header>
    <v-expansion-panel-content style="padding-top:16px;">
      <v-layout wrap>
        <v-flex xs12>
          <categories-list
            :categories="categories"
            :locale="locale"
            :onEdit="handleEdit"
            :onDelete="handleDelete"
          />
        </v-flex>
        <v-flex xs12 style="text-align: right;margin-top: 20px;padding: 10px;">
          <categories-item
            :editedItem="editedItem"
            :onSave="handleSave"
            :onClose="handleClose"
            :locale="locale"
            :dialog="showDialog"
            :languages="languages"
          />
          <v-btn block :elevation="0" color="primary" @click="handleAdd">
            {{ $t('common.add', locale) }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
import CategoriesItem from './CategoriesItem'
import CategoriesList from './CategoriesList'
export default {
  name: 'CategoriesForm',
  components: {
    CategoriesList,
    CategoriesItem,
  },
  props: {
    locale: {
      type: String,
      default: null,
    },
    onChangeData: {
      type: Function,
      default: null,
    },
    workspaceID: {
      type: String,
      default: null,
    },
    languages: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    editedItem: null,
    showDialog: false,
    categories: null,
  }),
  methods: {
    getData () {
      api.getAllWithoutLimit('booking', `v1/system/workspaces/${this.workspaceID}/categories`)
        .then(response => {
          this.categories = response ? response.map(item => {
            item.Name = JSON.parse(item.Name)
            return item
          }) : []
        })
    },
    handleAdd () {
      this.editedItem = {
        ID: utils.getNewID(),
        WorkspaceID: this.workspaceID,
        Name: {es: ''},
        isNew: true,
      }
      this.showDialog = true
    },
    handleEdit (v) {
      this.editedItem = JSON.parse(JSON.stringify(this.categories.filter(x => x.ID === v.ID).shift()))
      this.showDialog = true
    },
    handleDelete(v) {
      this.categories = this.categories.filter(x => x.ID !== v)
      this.handleChangeIsModified()
    },
    handleSave (v) {
      this.categories = this.categories.filter(x => x.ID !== v.ID)
      this.categories.push(v)
      this.handleChangeIsModified()
      this.handleClose()
    },
    handleExpand () {
      if (!this.workspaceID) this.categories = []
      else if (this.categories === null) this.getData()
    },
    handleClose () {
      this.editedItem = null
      this.showDialog = false
    },
    handleChangeIsModified () {
      this.onChangeData(this.categories)
    },
  },
}
</script>

