<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t(`booking.categories`, locale) }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row
              v-if="editedItem"
            >
              <v-col
                cols="12"
                sm="12"
                style="padding: 0px 23px;"
              >
                <translate
                  v-model="editedItem.Name"
                  :languages="languages"
                  :label="$t('booking.name', locale)"
                  type="text"
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                style="padding: 20px 15px;"
              >
                <color-picker-item
                  :value="editedItem.Color"
                  :label="$t('common.color', locale)"
                  :onChange="(v) => { editedItem.Color = v }"
                  style="border-radius:5px;"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="default"
            text
            @click="() => { onClose() }"
          >
            {{ $t('common.close', locale) }}
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="handleSave"
          >
            {{ $t('common.save', locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
//import api from '@/services/api'
//import utils from '@/services/utils'
import Translate from '@/components/Translate'
import ColorPickerItem from '@/components/common-components/ColorPickerItem'
export default {
  components: {
    Translate,
    ColorPickerItem,
  },
  props: {
    editedItem: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    onSave: {
      type: Function,
      required: true,
    },
    onClose: {
      type: Function,
      default: null,
    },
    languages: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    showDialog: false,
    loading: false,
  }),
  watch: {
    dialog () {
      this.showDialog = this.dialog
    },
  },
  mounted () {
    this.showDialog = this.dialog
  },
  methods: {
    handleSave () {
      this.onSave(this.editedItem)
    },
  },
}
</script>

